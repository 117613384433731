import React, { useEffect, useRef } from 'react';

import { Field } from '../../../components/field';
import { Link } from '../../../hooks/router';
import { Alert } from '../../../components/alert';
import { useNotification } from '../../../hooks/notification/Notification';
import { useModal } from '../../../hooks/modal/Modal';
import Api from '../../../services/Api';
import { Input, Select, useForm } from '../../../hooks/form';
import { FileList } from '../../../components/fileList';
import CreatePayerModal from '../CreatePayerModal';
import UpdatePayerModal from '../UpdatePayerModal';
import SelectPackageClassesModal from '../SelectPackageClassesModal';

import './overview.scss';
import BillModal from '../../bills/Ressource';
import moment from 'moment';

export default function RegistrationRessourceOverview({ registration, setRegistration }) {
  const notification = useNotification();
  const modal = useModal();
  const priceForm = useForm({
    tarifType: registration.tarifType || 'fullPriceTtc',
    price: registration.price || 0,
  });
  const init = useRef(false);

  useEffect(() => {
    if (init.current === true) {
      Api.post(`/registrations/${registration.id}/tarif-type`, {
        tarifType: priceForm.data.tarifType
      }).then((response) => {
        setRegistration(response.data);
        notification.notify('Le tarif a été mis à jour !');
      })
    }
    init.current = true;
  }, [priceForm.data.tarifType]);

  function savePrice(event) {
    event.stopPropagation();
    event.preventDefault();

    Api.post(`/registrations/${registration.id}/price`, {
      price: parseFloat(priceForm.data.price.replace(',', '.'), 10)
    }).then((response) => {
      setRegistration(response.data);
      notification.notify('Le prix a été mis à jour !');
    });
  }

  function onPayerCreated(registration) {
    setRegistration(registration);
  }

  function onPayerRemove(payer) {
    Api.del(`/registrations/${registration.id}/payers/${payer.id}`)
      .then(response => {
        setRegistration(response.data);
        notification.notify('Le payeur a été retiré !');
      }).catch(error => {
        alert('Une erreur est survenue');
      });
  }

  function getPrice(withOptions = true) {
    const price = registration.classe ? registration.classe[priceForm.data.tarifType] : registration.package[priceForm.data.tarifType];
    if (registration.tarifType === 'specific') {
      return registration.price || 0;
    }
    if (withOptions) {
      return registration.classeOptions?.reduce(
        (price, option) => price + option.classeOption.price,
        price
      );
    }
    return price;
  }

  return (
    <div className="ressource-page" style={{ padding: '20px', display: 'flex', flexDirection: 'row' }}>
      <div style={{ flex: 1, marginRight: '20px' }}>
        <h3 style={{ margin: '0px', marginBottom: '10px' }}>Informations</h3>
        <Field label="Status">
          {{
            'pending': 'En attente',
            'validated': 'Validée',
            'pendingPayment': 'En attente du paiement',
            'canceled': 'Annulée',
            'draft': 'Broullion',
          }[registration.status]}
        </Field>
        <Field label="Département bénéficiaire">
          {registration.department ? ({
            'cp': 'Cours Publics',
            'fnd': 'FND',
            'issr': 'ISSR',
            'fr': 'FEB',
            'ccdej': 'CCDEJ',
            'fcf': 'FCF',
            'e-learning': 'ELEARNING',
            'other': 'Autre',
          }[registration.department]) :  (<div style={{ color: 'red', display: 'flex', alignItems: 'center', gap: '8px' }}>
            <svg width="24" height="24" fill="none" viewBox="0 0 24 24">
              <path stroke="currentColor" strokeLinecap="round" stroke-linejoin="round" stroke-width="1.5" d="M4.9522 16.3536L10.2152 5.85658C10.9531 4.38481 13.0539 4.3852 13.7913 5.85723L19.0495 16.3543C19.7156 17.6841 18.7487 19.25 17.2613 19.25H6.74007C5.25234 19.25 4.2854 17.6835 4.9522 16.3536Z"></path>
              <path stroke="currentColor" strokeLinecap="round" stroke-linejoin="round" stroke-width="2" d="M12 10V12"></path>
              <circle cx="12" cy="16" r="1" fill="currentColor"></circle>
            </svg>
            Aucun
            <svg width="24" height="24" fill="none" viewBox="0 0 24 24">
              <path stroke="currentColor" strokeLinecap="round" stroke-linejoin="round" stroke-width="1.5" d="M4.9522 16.3536L10.2152 5.85658C10.9531 4.38481 13.0539 4.3852 13.7913 5.85723L19.0495 16.3543C19.7156 17.6841 18.7487 19.25 17.2613 19.25H6.74007C5.25234 19.25 4.2854 17.6835 4.9522 16.3536Z"></path>
              <path stroke="currentColor" strokeLinecap="round" stroke-linejoin="round" stroke-width="2" d="M12 10V12"></path>
              <circle cx="12" cy="16" r="1" fill="currentColor"></circle>
            </svg>
          </div>)}
        </Field>
        <Field label="Inscris le">
          {moment(registration.createdAt).format('DD/MM/YYYY')}
        </Field>
        <Field label="Code postal">
          {registration.zipcode || 'n/a'}
        </Field>
        <Field label="Origine">
          { registration.campusOrderId ? (
            <>
              Commande WooCommerce <a target="_blank" href={`${Api.CAMPUS_ADMIN_URL}/post.php?post=${registration.campusOrderId}&action=edit`}>#{registration.campusOrderId}</a>
            </>
          ) : registration.shopifyOrderId ? (
            <>
              Commande Shopify <a target="_blank" href={`${Api.SHOPIFY_ADMIN_URL}/orders/${registration.shopifyOrderId}`}>#{registration.shopifyOrderId}</a>
            </>
          ) : (
            <>
              {{
                'admin': 'Admin',
                'web': 'Web',
                'import-csv': 'Import CSV',
                null: 'n/a',
              }[registration.origin]}
            </>
          )}
        </Field>
        { registration.package &&
          <>
            <h3 style={{ margin: '0px', marginTop: '20px', marginBottom: '10px' }}>Cours choisis</h3>
            { registration.classes.length === 0 &&
              <div style={{ padding: '20px', color: 'grey', textAlign: 'center' }}>
                Aucun cours choisis
              </div>
            }
            {
              registration.classes.map(registrationClasse => (
                <div key={registrationClasse.classe.id} style={{ borderRadius: '8px', border: '1px solid #ccc', padding: '20px', marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
                  <Link to="classe" params={{ id: registrationClasse.classe.id }} style={{ flex: 1 }}>
                    {registrationClasse.classe.number} • {registrationClasse.classe.name}
                  </Link>
                </div>
              ))
            }
            <button className="btn full" onClick={() => modal.open(<SelectPackageClassesModal registration={registration} onAdded={registration => setRegistration(registration)} />, { size: 'full' })}>Modifier les cours</button>
            <div style={{ fontSize: '0.9em', color: 'red' }}>Attention, cela permet d'ajuster les cours, mais pas l'inscription en tant que tel (prix, facture, etc...)</div>
          </>
        }
        <h3 style={{ margin: '0px', marginTop: '20px', marginBottom: '10px' }}>Paiement</h3>
        {(
          ((
            registration.classe && (
              registration.classe.fullPriceTtc === null
              || registration.classe.individualPriceTtc === null
              || registration.classe.youthPriceTtc === null
            )
          )
          || (
            registration.package && (
              registration.package.fullPriceTtc === null
              || registration.package.individualPriceTtc === null
              || registration.package.youthPriceTtc === null
            )
          ))
          && registration.status === 'pending'
        ) ? (
          <Alert type="red">
            Le {registration.classe ? 'cours' : 'package'} n'a pas de tarifs définis. <Link to={registration.classe ? 'classe' : 'package'} params={{ id: registration[registration.classe ? 'classe' : 'package'].id }}>Modifier le {registration.classe ? 'cours' : 'package'}</Link>.
          </Alert>
        ) : (
          <>
            <Field label="Type" box={registration.status === 'pending'}>
            { registration.status === 'pending' &&
                <Select noSearch {...priceForm.field('tarifType')}>
                  <option value="fullPriceTtc">Tarif Institutions</option>
                  <option value="individualPriceTtc">Tarif individuel</option>
                  <option value="youthPriceTtc">Tarif - 30 ans</option>
                  <option value="specific">Spécifique</option>
                </Select>
              }
              { registration.status !== 'pending' &&
                <>{{
                  fullPriceTtc: 'Tarif Institutions',
                  individualPriceTtc: 'Tarif individuel',
                  youthPriceTtc: 'Tarif - 30 ans',
                  specific: 'Spécifique',
                }[registration.tarifType]}</>
              }
            </Field>
            <Field label="Prix" box={registration.tarifType === 'specific' && registration.status === 'pending'}>
              { registration.tarifType === 'specific' && registration.status === 'pending' ? (
                <form className="form" style={{ display: 'flex' }} onSubmit={savePrice}>
                  <Input type="number" step="0.1" {...priceForm.field('price', { type: 'float' })} suffix="€" style={{ flex: 1 }} />
                  { registration.price != priceForm.data.price &&
                    <button className="btn" style={{ marginLeft: '20px' }}>Enregistrer</button>
                  }
                </form>
              ) : (
                <>
                  {getPrice()}€
                </>
              )}
            </Field>
            <Field label="Payeurs" box>
              {
                registration.payers.sort(payer => payer.amount - payer.amount).map(payer => (
                  <div key={payer.id} onClick={() => payer.bill ? (modal.open(<BillModal billId={payer.bill.id} billNumber={payer.bill.number} billType={payer.bill.type} />)) : null} style={{ cursor: (payer.bill ? 'pointer' : 'auto'), borderRadius: '8px', border: '1px solid #ccc', padding: '20px', marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
                    <div style={{ flex: 1 }}>
                      { payer.bill && /* overkill test for production */
                        <div>
                          Facture #{payer.bill.number}&nbsp;•&nbsp;
                          {{
                            'pending': 'En attente',
                            'paid': 'Payée',
                            'canceled': 'Annulée',
                          }[payer.bill.status]}
                        </div>
                      }
                      <div style={{ fontWeight: 500, fontSize: '1.1em' }}>
                        { payer.payer.type === 'company' ? (
                          <>{payer.payer.companyName}</>
                        ) : (
                          <>{payer.payer.user.firstname} {payer.payer.user.lastname.toUpperCase()}</>
                        )}
                        { (registration.status === 'pending' || registration.noBillCreation) &&
                          <small style={{ color: '#007bff', cursor: 'pointer', fontWeight: 500 }} onClick={() => modal.open(<UpdatePayerModal registration={registration} payer={payer} onUpdated={registration => onPayerCreated(registration)} />)}> • modifier</small>
                        }
                      </div>
                      <div style={{ color: 'grey' }}>
                        {payer.payer.user.email} • {{ company: 'Institution', people: 'Personne', student: 'Étudiant' }[payer.payer.type]}
                      </div>
                    </div>
                    <div style={{ flex: '0 0 auto', textAlign: 'right' }}>
                      <span style={{ fontWeight: 500, fontSize: '1.1em' }}>{payer.amount}€</span>
                    </div>
                    { (registration.status === 'pending' || registration.noBillCreation) &&
                      <svg style={{ width: '12px', cursor: 'pointer', marginLeft: '10px' }} onClick={() => onPayerRemove(payer.payer)} version="1.1" viewBox="0 0 20 20" x="0px" y="0px"><g><path d="M8.5 10L4 5.5 5.5 4 10 8.5 14.5 4 16 5.5 11.5 10l4.5 4.5-1.5 1.5-4.5-4.5L5.5 16 4 14.5 8.5 10z"></path></g></svg>
                    }
                  </div>
                ))
              }
              { (registration.status === 'pending' || registration.noBillCreation) &&
                <>
                  { registration.payers.length > 0 &&
                    <div style={{ paddingBottom: '2px', fontSize: '0.9em', paddingLeft: '4px' }}>L'étudiant paiera le reste dû :</div>
                  }
                  <div style={{ borderRadius: '8px', background: '#E9E9E9', cursor: 'not-allowed', border: '1px solid #ccc', padding: '20px', marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
                    <div style={{ flex: 1 }}>
                      <div style={{ fontWeight: 500, fontSize: '1.1em' }}>
                        {registration.student.user.firstname} {(registration.student.user.lastname ? registration.student.user.lastname.toUpperCase() : '')}
                      </div>
                      <div style={{ color: 'grey' }}>
                        Étudiant
                      </div>
                    </div>
                    <div style={{ flex: '0 0 auto', textAlign: 'right' }}>
                      <span style={{ fontWeight: 500, fontSize: '1.1em' }}>{getPrice() - registration.payers.reduce((paid, payer) => paid + payer.amount, 0)}€</span>
                    </div>
                  </div>
                </>
              }
              { (registration.status === 'pending' || registration.noBillCreation) &&
                <>
                  <button className="btn full" onClick={() => modal.open(<CreatePayerModal registration={registration} onCreated={registration => onPayerCreated(registration)} />)}>Ajouter un payeur</button>
                </>
              }
            </Field>
          </>
        )}
      </div>
      <div style={{ width: '350px' }}>
        <h3 style={{ margin: '0px' }}>Étudiant</h3>
        <div style={{ marginTop: '10px', borderRadius: '8px', border: '1px solid #ccc', padding: '16px', display: 'flex', alignItems: 'center' }}>
          <Link to="student" params={{ id: registration.student.id }} style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M14.25 7C14.25 8.24264 13.2426 9.25 12 9.25C10.7574 9.25 9.75 8.24264 9.75 7C9.75 5.75736 10.7574 4.75 12 4.75C13.2426 4.75 14.25 5.75736 14.25 7Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
              <path d="M12 9.75C8.6 9.75 7.75 11.5 7.75 14.25H9.75V17.25C9.75 18.3546 10.6454 19.25 11.75 19.25H12.25C13.3546 19.25 14.25 18.3546 14.25 17.25V14.25H16.25C16.25 11.5 15.4 9.75 12 9.75Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
            </svg>
            {registration.student.user.firstname} {(registration.student.user.lastname ? registration.student.user.lastname.toUpperCase() : '')}
          </Link>
        </div>

        { registration.classe &&
          <>
            <h3 style={{ margin: '0px', marginTop: '20px' }}>Cours</h3>
            <div style={{ marginTop: '10px', borderRadius: '8px', border: '1px solid #ccc', padding: '16px', display: 'flex', alignItems: 'center' }}>
              <Link to="classe" params={{ id: registration.classe.id }} style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                <svg width="24" height="24" fill="none" viewBox="0 0 24 24">
                  <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M4.75 6.75C4.75 5.64543 5.64543 4.75 6.75 4.75H17.25C18.3546 4.75 19.25 5.64543 19.25 6.75V14.25C19.25 15.3546 18.3546 16.25 17.25 16.25H6.75C5.64543 16.25 4.75 15.3546 4.75 14.25V6.75Z"></path>
                  <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M15.25 19.25L12 17.25L8.75 19.25"></path>
                </svg>
                {registration.classe.name} {registration.classe.number}
              </Link>
            </div>
          </>
        }

        { registration.shopifyVariantId &&
          <>
            <h3 style={{ margin: '0px', marginTop: '20px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              Produit Shopify
            </h3>
            <div style={{ marginTop: '10px', marginBottom: '10px', borderRadius: '8px', border: '1px solid #ccc', display: 'flex', alignItems: 'center', gap: '16px', position: 'relative' }}>
              { registration.shopifyImageUrl ? <img style={{ width: '40px', height: '60px', flex: '0 0 auto', objectFit: 'cover' }} src={registration.shopifyImageUrl} alt="" /> : <div className="fake-img"></div> }
              <div style={{ flex: 1, padding: '10px 0px' }}>
                <a target="_blank" href={`https://college-des-bernardins.myshopify.com/admin/products/${registration.shopifyProductId}`}>{registration.shopifyProductTitle}</a>
                <br />
                • {registration.shopifyVariantTitle}
              </div>
            </div>
          </>
        }

        { registration.package &&
          <>
            <h3 style={{ margin: '0px', marginTop: '20px' }}>Package</h3>
            <div style={{ marginTop: '10px', borderRadius: '8px', border: '1px solid #ccc', padding: '16px', display: 'flex', alignItems: 'center' }}>
              <Link to="package" params={{ id: registration.package.id }} style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                  <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M5.75 8.87 12 12.25M5.75 8.87v7L12 19.25M5.75 8.87l3.125-1.56m9.375 1.56L12 5.75 8.875 7.31m9.375 1.56v7L12 19.25m6.25-10.38-3.125 1.69M12 12.25v7m0-7 3.125-1.69m0 0-6.25-3.25"></path>
                </svg>
                {registration.package.name}
              </Link>
            </div>
          </>
        }

        <FileList entity="registrations" entityId={registration.id} />
      </div>
      
    </div>
  );
}
