import React, { useReducer, useContext, createContext } from "react";

const appContext = createContext();
appContext.displayName = 'App';

export function ProvideApp({ children, reducer, initialArg = {} }) {
  const app = useProvideApp(reducer, initialArg);
  return <appContext.Provider value={app}>{children}</appContext.Provider>;
}

export const useApp = () => {
  return useContext(appContext);
};

function useProvideApp(reducer, initialArg = {}) {
  const [state, dispatch] = useReducer(reducer, initialArg);
  
  return { state, dispatch: (action, params) => dispatch({ action, ...params }) };
};
