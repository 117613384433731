import React from 'react';

import './guest.scss';

export default function GuestLayout({ children }) {
  return (
    <div className="guest-layout">
      <div className="content">
        <div className="wrapper">
            {children}
        </div>
        <footer>
          &copy; 2023 Bernardins
        </footer>
      </div>
    </div>
  );
};
