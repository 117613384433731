import moment from 'moment';

function objectToString(object, prefix, flatObject = true) {
  return Object.keys(object).map(key => ({ key, value: object[key] })).reduce((paramsArray, { key, value }) => {
    const keyString = prefix ? `${prefix}[${key}]` : key;
    if (value === null) {
      return paramsArray;
    }
    if (typeof value === 'boolean') {
      if (value === true) {
        return [...paramsArray, `${keyString}`];
      }
    } else if (typeof value === 'string') {
      if (value !== '') {
        return [...paramsArray, `${keyString}=${value}`];
      }
    } else if (moment.isDate(value) || moment.isMoment(value)) {
      if (value !== '') {
        return [...paramsArray, `${keyString}=${moment(value).format('DD-MM-YYYY')}`];
      }
    } else if (Array.isArray(value)) {
      if (value.length > 0) {
        return [...paramsArray, `${keyString}=${value.join(',')}`];
      }
    } else if (typeof value === 'object') {
      if (flatObject) {
        return [...paramsArray, objectToString(value, keyString)];
      } else {
        return [...paramsArray, `${keyString}=${value.id}`];
      }
    } else {
      return [...paramsArray, `${keyString}=${value}`];
    }
    return paramsArray;
  }, []).join('&')
}

export const queryToUrl = (params, flatObject = true) => {
  if (params === undefined) {
    return '';
  }

  return '?' + objectToString(params, null, flatObject);
};
