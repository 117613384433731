import React, { useState, useContext, createContext } from "react";
import { uniqueId } from "lodash";

import './notification.scss';

const notificationContext = createContext();
notificationContext.displayName = 'Notification';

export function ProvideNotification({ children }) {
  const notification = useProvideNotification();

  return (
    <notificationContext.Provider value={notification}>
      { notification.list.length > 0 &&
        <div className={`component-notification`}>
          { notification.list.map(notif => (
            <div key={notif.id} className={`notification`}>
              <div className="content" onClick={notif.onClick}>
                {notif.message}
              </div>
              <div className="close" onClick={notif.close}>
                <i className="fas fa-times"></i>
              </div>
            </div>
          ))}  
        </div>
      }
      {children}
    </notificationContext.Provider>
  );
}

export const useNotification = () => {
  return useContext(notificationContext);
};

function useProvideNotification() {
  const [list, setList] = useState([]);
  
  const notify = (message, onClick, { ttl = 5000 } = {}) => {
    const id = uniqueId();
    const notif = {
      id,
      message,
      close: () => {
        setList(prevList => prevList.filter(item => item.id !== id));
      },
      onClick: () => {
        if (typeof onClick === 'function') {
          onClick(notif);
          notif.close();
        } else {
          notif.close();
        }
      }
    };
    setList(prevList => ([ ...prevList, notif ]));
    setTimeout(() => {
      notif.close();
    }, ttl);
  }

  return {
    notify,
    list
  };
};