import axios from 'axios';

import { getCookie } from '../hooks/auth/session';
import { queryToUrl } from '../hooks/router';

const TOKEN_COOKIE_NAME = 'xTokBernardinsAdmin';
const API_URL = 'https://api.collegedesbernardins.fr';
// const API_URL = 'http://localhost:8084';
// const API_URL = 'https://api.preprod.collegedesbernardins.fr';

// const CAMPUS_ADMIN_URL = 'https://dev.lecampusdesbernardins.fr/wp-admin';
const CAMPUS_ADMIN_URL = 'https://lecampusdesbernardins.fr/wp-admin';
const SHOPIFY_ADMIN_URL = 'https://admin.shopify.com/store/college-des-bernardins';

function toPlural(name) {
  if (name.slice(-1) === 'y') {
    return `${name.slice(0, -1)}ies`;
  }
  return `${name}s`;
}

async function get(endpoint, queries = {}, source) {
  return axios.get(`${API_URL}${endpoint}${queryToUrl(queries, false)}`, { 
    cancelToken: source ? source.token : undefined,
    headers: { Authorization: `Bearer ${getCookie(TOKEN_COOKIE_NAME)}` },
  });
}

async function post(endpoint, data = {}, source, noAuthorization = false) {
  return axios.post(`${API_URL}${endpoint}`, data, { 
    cancelToken: source ? source.token : undefined,
    headers: noAuthorization === false ? { Authorization: `Bearer ${getCookie(TOKEN_COOKIE_NAME)}` } : undefined,
  });
}

async function upload(endpoint, file, progressCallback, source) {
  var formData = new FormData();
  formData.append("file", file);
  return axios.post(`${API_URL}${endpoint}`, formData, { 
    cancelToken: source ? source.token : undefined,
    onUploadProgress: function(progressEvent) {
      var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
      progressCallback({ loaded: progressEvent.loaded, total: progressEvent.total, percent: percentCompleted });
    },
    headers: { 
      Authorization: `Bearer ${getCookie(TOKEN_COOKIE_NAME)}`,
      'Content-Type': 'multipart/form-data'
    },
  });
}

async function del(endpoint, source) {
  return axios.delete(`${API_URL}${endpoint}`, { 
    cancelToken: source ? source.token : undefined,
    headers: { Authorization: `Bearer ${getCookie(TOKEN_COOKIE_NAME)}` },
  });
}

async function findAll(entity, queries, { source, returnName } = {}) {
  const response = await get(`/${entity}`, queries, source);
  return {
    [returnName || entity]: response.data,
    source,
    count: response.headers['x-count']
  };
};

async function create(entity, data, { source, returnName } = {}) {
  const response = await post(`/${toPlural(entity)}`, data, source);
  return {
    [returnName || entity]: response.data,
    source
  };
};

async function findOne(entity, id, { source, returnName } = {}) {
  const response = await get(`/${toPlural(entity)}/${id}`, {}, source);
  return {
    [returnName || entity]: response.data,
    source
  };
};

function createRequestToken() {
  const CancelToken = axios.CancelToken;
  return CancelToken.source();
}

export default {
  findAll,
  create,
  findOne,
  get,
  post,
  upload,
  del,
  createRequestToken,
  API_URL,
  SHOPIFY_ADMIN_URL,
  CAMPUS_ADMIN_URL,
};
