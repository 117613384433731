function parseCookies () {
  var list = {},
      rc = document.cookie;

  rc && rc.split(';').forEach(function( cookie ) {
      var parts = cookie.split('=');
      list[parts.shift().trim()] = decodeURI(parts.join('='));
  });

  return list;
}

const getCookie = (cookieName) => {
  let cookie = null; // @todo get cookie for ssr
  if (typeof document !== 'undefined') {
    cookie = parseCookies()[cookieName];
  }

  if (cookie === undefined) {
    cookie = null;
  }

  return cookie;
}

const setCookie = (cookieName, value) => {
  document.cookie = `${cookieName}=${value}; path=/;`;
}

const removeCookie = (cookieName) => {
  document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
}

export {
  getCookie,
  setCookie,
  removeCookie,
};