import React, { useEffect } from 'react';
import ListHeaders from './ListHeader';

import './list.scss';
import { Loader } from '../../components/loader';

export default function List({ children, headers, isLoading, onLoadMore, noPagination, scrollableClassName }) {
  useEffect(() => {
    if (!noPagination) {
      const onScroll = () => {
        // list height
        const listHeight = document.querySelector(scrollableClassName || '.page').clientHeight;
        // list top position
        const scrollTop = document.querySelector(scrollableClassName || '.page').scrollTop;
        // height of the list 
        const scrollHeight = document.querySelector(scrollableClassName || '.page').scrollHeight;
        if ((scrollTop + listHeight) + 350 >= scrollHeight) {
          onLoadMore();
        }
      }

      document.querySelector(scrollableClassName || '.page').addEventListener("scroll", onScroll);
      return () => {
        document.querySelector(scrollableClassName || '.page').removeEventListener("scroll", onScroll);
      }
    }
  }, [onLoadMore]);

  return (
    <div className="list-component">
      <div className="content">
        <div className="wrapper">
          { headers && <ListHeaders headers={headers} /> }
          <div className="list">
            { children }
          </div>
          { isLoading &&
            <Loader />
          }
        </div>
      </div>
    </div>
  )
};
